import React from 'react'
import Tooltip from './Tooltip'

function HeadComponent(props) {
    return (
            <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
                <div className='col-span-1'>
                    <img src={props.image} alt={props.name} className='rounded-full border-2 border-[#FDFFFC] max-h-[250px]'/>
                </div>
                <div className='col-span-2'>
                    <div className='flex items-center'>
                        <p className='text-2xl md:text-3xl font-semibold flex items-center'>
                            {props.name} {props.lastname}
                            <Tooltip text="This user has been verified, indicating that a real person genuinely manages this profile">
                                <img src='/images/verified.svg' alt='Verified User' className='ml-2'/>
                            </Tooltip>
                            
                        </p>
                    </div>
                    
                    <p className='text-sm font-light text-[#1d1d1d8e]'>{props.title}</p>
                    <p className='text-base font-semibold mt-4 mb-3'>Information:</p>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-0 lg:gap-6'>
                        <div>
                            <p className='text-[#1d1d1d8e] text-xs'>Location:<span>&ensp;{props.location}</span></p>
                            <p className='text-[#1d1d1d8e] text-xs'>Phone:<span>&ensp;{props.phone}</span></p>
                            <p className='text-[#1d1d1d8e] text-xs'>Email:<a href={'mailto:' + props.email} 
                            className='text-[#1D64C1]'>&ensp;{props.email}</a></p>
                            
                        </div>
                        <div>
                            <p className='text-[#1d1d1d8e] text-xs'>Gender:<span>&ensp;{props.gender}</span></p>
                            <p className='text-[#1d1d1d8e] text-xs'>Pronouns:<span>&ensp;{props.pronouns}</span></p>
                            <p className='text-[#1d1d1d8e] text-xs'>Language:<span>&ensp;{props.language}</span></p>
                        </div>
                    </div>
                    <p className='text-[#1d1d1d] text-xs mt-4'>External Link:</p>
                            <div className='flex items-center mt-2'>
                                <a className='text-[#1d64c1] text-xs' target='_blank' rel="noreferrer" href={props.external_link_two}>
                                    {props.external_link_two_title}</a>
                                <a target='_blank' rel="noreferrer" href={props.external_link_two}>
                                    <img className='h-3 w-3 ml-1' src='/images/external_link_two.svg' alt='External Website' />
                                    </a>
                            </div>
                </div>
            </div>
            
    )
}

export default HeadComponent