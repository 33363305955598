import React, { useState } from 'react';

const Tooltip = ({ text, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div
            className="relative inline-block"
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {isVisible && (
                <span className="absolute -left-1/3 lg:left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-44 bg-[#1d1d1d] text-[#fdfffc] shadow-xl text-left font-light text-xs rounded-xl py-3 px-4 z-10">
                    {text}
                </span>
            )}
        </div>
    );
};

export default Tooltip;
