import React from 'react';
import { SwiperSlide } from 'swiper/react';

function SocialCard(props) {
    return (
        //  className='mb-8 pb-6' for SwiperSlide
        <SwiperSlide className='pb-6 cursor-pointer'>
            <a target='_blank' rel="noreferrer" href={props.link}>
                <div className='bg-[#f3f4f5] p-2 flex items-center justify-center size-20 rounded-xl shadow-md'>
                    <img src={props.logo} alt={props.alt} className='size-14'/>
                </div>
            </a>
        </SwiperSlide>
    )
}

export default SocialCard