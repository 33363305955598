import React , { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import Modal from './Modal';
import { StoriesData } from './data';
import StoriesCard from './StoriesCard';

function Stories() {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModal = (image) => {
        setSelectedImage(image);
        setIsOpen(true);
    };

    const init = () => {

    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage('');
    };

    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] w-full md:max-w-[900px] lg:w-[900px] py-10 md:py-10 shadow-xl'>
                <p className='text-2xl font-semibold mb-8 px-6 md:px-20 '>Stories</p>

                <Swiper
                    style={{
                        "--swiper-pagination-color": "#1D65C1",
                        "--swiper-pagination-bullet-inactive-color": "#989997",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "8px",
                        "--swiper-pagination-bullet-horizontal-gap": "6px",
                    }}
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={false}
                    breakpoints={{
                        0: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        400: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                        640: {
                            slidesPerView: 1.2,
                            spaceBetween: 20,
                        },
                        735: {
                            slidesPerView: 1.2,
                            spaceBetween: 60,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        1124: {
                            slidesPerView: 8,
                            spaceBetween: 10,
                        }
                    }}
                    pagination={false}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper max-w-[1240px] w-full"
                >
                    
                    {StoriesData.map((item) => (
                        <SwiperSlide key={item.id} >
                            <div onClick={() => openModal(item.url_story)}>
                                <StoriesCard
                                    id={item.id}
                                    alt_story={item.alt_story}
                                    url_story={item.url_story}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <Modal isOpen={isOpen} onClose={closeModal} image={selectedImage} />
                
            </div>
        </section>
    )
}

export default Stories