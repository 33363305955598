import React from 'react';

const Modal = ({ isOpen, onClose, image }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay} onClick={onClose}>
      <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <img src={image} alt="Larger view" style={styles.image} />
        <button onClick={onClose} style={styles.closeButton}>
          &times;
        </button>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    position: 'relative',
    maxWidth: '90%',
    maxHeight: '90%',
    overflow: 'hidden',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '80vh', // Limit the height
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    background: '#fdfffc',
    border: "1px",
    color: '#1d1d1d',
    fontSize: '24px',
    cursor: 'pointer',
    zIndex: 1001,
  },
};

export default Modal;
