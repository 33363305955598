import React from 'react';


function AboutComponent(props) {
    return (
        <div>
            <p className='text-sm'>{props.about}</p>
        </div>
    )
}

export default AboutComponent