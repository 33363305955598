export const Data = [
    {
        id: 1,
        /* Banner Data */


        /* Head Data */
        image: "/images/myself.jpg", // MUST BE SQUARE
        quote: "Hello, there! This is my Nova profile.",
        name: "Johan",
        lastname: "Olmos",
        title: "Founder & CEO of Astrobie LLC",
        pronouns: "He/Him",
        gender: "Male",
        language: "Spanish | English",
        
        /* Description Data */
        location: "Chicago, IL",
        phone: "",
        email: "contact@astrobie.com",
        external_link_two: "https://astrobie.com",
        external_link_two_title: "Astrobie",

        /* About Data */
        about: "As the Founder & CEO of Astrobie LLC, I combine technical expertise with leadership. Skilled in front-end technologies like React and back-end with Django, I build user-centered web applications. My team focuses on creating innovative tech solutions that enhance our clients' lives and businesses.",

        /* Job Data (up to three) retrieved from datajob.js*/
        
        /* Social Data is retrieved from datasocial.js file */
        
        /* Education Data (May be more than one record) retrieved from dataeducation,js */
        
    }
]

export const StoriesData = [
    {
        id: 1,
        url_story: "https://media.discordapp.net/attachments/1293020375617372192/1305961127548686396/IMG_7614.jpg?ex=6734ee94&is=67339d14&hm=f05499d4d7bf1274303664523d291d811654108104165ed128297d37210cde3c&=&format=webp&width=486&height=648",
        alt_story: "Me",
    },
    {
        id: 2,
        url_story: "https://media.discordapp.net/attachments/1293020375617372192/1305961125921292338/IMG_6393.jpg?ex=6734ee94&is=67339d14&hm=4e6347bf7d3696caf03fbe57e7fb973b94a0bea09566a3554217985e1cb72f30&=&format=webp&width=486&height=648",
        alt_story: "Me",
        title_story: "Second story",
        date_story: "Jan, 2023",
        description_story: "This text serves as description for the second story"
    },
    {
        id: 3,
        url_story: "https://media.discordapp.net/attachments/1293020375617372192/1305961126722539570/IMG_0582.jpg?ex=6734ee94&is=67339d14&hm=98be0fd4d20eec7bba878ebd77d5fc65f88e8de012097c78d5c7cc32c199d701&=&format=webp&width=486&height=648",
        alt_story: "Me",
        title_story: "Second story",
        date_story: "Jan, 2023",
        description_story: "This text serves as description for the second story"
    },
    {
        id: 4,
        url_story: "https://media.discordapp.net/attachments/1293020375617372192/1305962327610228788/astrobie.jpg?ex=6734efb2&is=67339e32&hm=2ff2d08f0c290e2a8fa3e466294ccca3f3b5c50c4c98d2a39c3ca04eba879a2c&=&format=webp&width=486&height=648",
        alt_story: "Me",
        title_story: "Second story",
        date_story: "Jan, 2023",
        description_story: "This text serves as description for the second story"
    },{
        id: 5,
        url_story: "https://media.discordapp.net/attachments/1293020375617372192/1305961129314615306/IMG_3370.jpg?ex=6734ee94&is=67339d14&hm=3fd3d76e6d9578a36cddb358c509bd3382207d0dadc0805ce60b0393ebe6988a&=&format=webp&width=486&height=648",
        alt_story: "Me",
        title_story: "Second story",
        date_story: "Jan, 2023",
        description_story: "This text serves as description for the second story"
    },
    {
        id: 7,
        url_story: "https://images.pexels.com/photos/18164715/pexels-photo-18164715/free-photo-of-person-walking-a-dog-on-a-promenade.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        alt_story: "Me",
        title_story: "Second story",
        date_story: "Jan, 2023",
        description_story: "This text serves as description for the second story"
    },
]

export const EducationData = [
    {
        id: 1,
        college: "Lewis University",
        color: 'border-[#6b2d2d]',
        startyear: "2024",
        endyear: "2026",
        major: "BS Cybersecurity",
        gpa: "3.9",
        description: "Currently pursuing a degree in Cybersecurity, where I am learning Linux, C programming, and the fundamentals of computer organization. Exploring networks, operating system structures, algorithms, and web development. Engaging in penetration testing and related cybersecurity practices to develop practical skills and understanding of security measures."
    },
    {
        id: 2,
        college: "St. Augustine College",
        color: 'border-[#033C3F]',
        startyear: "2021",
        endyear: "2023",
        major: "AAS Application Programmer",
        gpa: "4.0",
        description: "Completed a technical degree in Application Programming, where I developed expertise in Python and Django. Designed and implemented web applications, collaborated on team projects, and applied best practices in software development. Enhanced user experiences through responsive web design and optimized application performance."
    },
]

export const ExperienceData = [
    {
        id: 1,
        company_name: "Astrobie LLC",
        title: "Founder & CEO of Astrobie LLC",
        yearone: "2024",
        yeartwo: "Present",
        description: "At Astrobie LLC, I lead the charge in delivering cutting-edge technology solutions and driving innovation. As the Founder and CEO, I am responsible for the overall vision, development process, and major decision-making within the company. I focus on ensuring that our products and services align with our mission to make technology a natural extension of our customers. From building strategic partnerships to overseeing product innovation, I lead our team with a focus on growth, client satisfaction, and technological advancement.",
        color: "border-[#1D1D1D]"
    },
]

export const SocialData = [
    {
        id: 1,
        alt: "Facebook",
        link: "https://www.facebook.com/people/Astrobie/61563474507043/?mibextid=LQQJ4d",
        logo: "/images/facebook.png",
    },
    {
        id: 2,
        alt: "LinkedIn",
        link: "https://www.linkedin.com/in/johan-olmos-22593b247/",
        logo: "/images/linkedin.png",
    },
    {
        id: 3,
        alt: "Instagram",
        link: "https://www.instagram.com/astrobie/?igsh=Zm5rNzN1Zmd3bHA4&utm_source=qr",
        logo: "/images/instagram.png",
    },
    {
        id: 9,
        alt: "YouTube",
        link: "https://youtube.com/@astrobie?si=Lzxiv2GRcsI0A7n_",
        logo: "/images/youtube.png"
    },
    
]

export const NewData = [
    {
        id: 4,
        alt: "Snapchat",
        link: "/",
        logo: "/images/snapchat.png"
    },
    {
        id: 5,
        alt: "Discord",
        link: "https://facebook.com",
        logo: "/images/discord.png"
    },
    {
        id: 6,
        alt: "GitHub",
        link: "/",
        logo: "/images/github.png"
    },
    {
        id: 7,
        alt: "X",
        link: "",
        logo: "/images/x.png"
    },
    {
        id: 8,
        alt: "WhatsApp",
        link: "",
        logo: "/images/whatsapp.png"
    },
]