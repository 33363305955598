import React from 'react';

function Footer() {
    return (
        <section className='bg-[#f3f4f5] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='flex items-center justify-center bg-[#1D1D1D] text-[#FDFFFC] w-full md:max-w-[900px] lg:w-[900px] h-12 px-6 md:px-20 shadow-xl py-2 md:py-4 rounded-b-2xl mb-14'>
                <a target='_blank' rel="noreferrer" href='https://astrobie.com'>
                    <img src='/images/nova-logo.png' alt='NovaCard' className='h-4'/>
                </a>
            </div>
        </section>
    )
}

export default Footer