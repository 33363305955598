import React, { useEffect, useState } from 'react';
import { RWebShare } from "react-web-share";
import axios from 'axios';
import VCard from 'vcard-creator';
import { Data } from './data';

function Links(props) {
    const [contactInfo, setContactInfo] = useState(null);
    const [imageBase64, setImageBase64] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    // Fetch contact information from the API
    const fetchContactInfo = async () => {
        try {
            const response = await axios.get('');
            setContactInfo(response.data);
        } catch (error) {
            console.error('Error fetching contact info:', error);
        }
    };

    useEffect(() => {
        fetchContactInfo(); // Fetch contact info when the component mounts

        // Load image and convert to Base64
        const loadImageAsBase64 = async () => {
            try {
                const response = await fetch('/images/myself.jpg');
                const blob = await response.blob();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageBase64(reader.result.split(',')[1]);
                };
                reader.readAsDataURL(blob);
            } catch (error) {
                console.error('Error loading image:', error);
            }
        };

        loadImageAsBase64();
    }, []);

    const downloadVCard = () => {
        // Define a new vCard
        const myVCard = new VCard();

        // Some variables
        const lastname = 'Olmos';
        const firstname = 'Johan';
        const additional = '';
        const prefix = '';
        const suffix = '';

        myVCard
            // Add personal data
            .addName(lastname, firstname, additional, prefix, suffix)
            // Add work data
            .addCompany('Astrobie LLC')
            .addJobtitle('Founder & CEO of Astrobie LLC')
            .addRole('Founder & CEO')
            .addEmail('jolmos@astrobie.com')
            .addPhoneNumber(3128970843, 'PREF;WORK')
            //.addAddress(null, null, 'street', 'worktown', null, 'workpostcode', 'Belgium')
            //.addSocial('https://twitter.com/desloovere_j', 'Twitter', 'desloovere_j')
            .addURL('http://www.astrobie.com');

        // Add photo if available
        if (imageBase64) {
            myVCard.addPhoto(imageBase64, 'JPEG');
        }

        // Create a Blob with the vCard content and generate a download link
        const blob = new Blob([myVCard.toString()], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = 'contact.vcf';

        // Append to the DOM, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Release the object URL
        URL.revokeObjectURL(url);
    };

    // Copy URL to Clipboard Handler
    const handleCopy = () => {
        const textToCopy = window.location.href; // You can replace this with any string you'd like.
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000); // Reset "Copied!" message after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] w-full md:max-w-[900px] lg:w-[900px] px-6 md:px-20 py-10 md:py-10 shadow-xl'>
                <p className='text-2xl font-semibold mb-8'>Quick Links</p>
                <div className='flex flex-row gap-3'>

                    {/* Save Contact Button */}
                    <button className='rounded-full py-2 px-3 bg-[#1D64C1] text-[#FDFFFC] text-sm 
                    hover:bg-[#29548b] duration-300' onClick={downloadVCard}>Save Contact</button>

                    {/* Share Button */}
                    <RWebShare
                        data={{
                            text: "Check out my Nova profile!",
                            url: "https://nova.astrobie.com/profile/johanolmos",
                            title: "Johan's Nova Profile",
                        }}
                        onClick={() => console.log("Shared successfully!")}
                    >
                        <button className='rounded-full py-2 px-2 bg-[#FDFFFC] text-[#1D1D1D] text-sm 
                        border-[1px] border-[#1d1d1d67] hover:bg-[#e1e3e0]
                        duration-300'><img src='/images/share.svg' alt='share' /></button>
                    </RWebShare>

                </div>
            </div>
        </section>
    );
}

/*
<button
className="rounded-full py-2 px-2 bg-[#1d1d1d] hover:bg-[#1d1d1dd8] duration-300 border-[1px] border-[#1d1d1d67]"
onClick={handleCopy}
>
<img src="/images/clipboard_baby_powder.svg" alt="Copy" />
</button>
*/
export default Links;
