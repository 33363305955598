import React from 'react'

function EducationComponent(props) {
    return (
        <div className={`border-l-[5px] ${props.color} px-3 my-2 py-2`}>
            <p className='text-base font-medium'><span>{props.college}&ensp;</span>
            <span className='font-light text-xs text-[#1d1d1d8e]'>({props.startyear} - {props.endyear})</span></p>
            <p className='text-sm text-[#1d1d1d8e] mb-2'>{props.major}&ensp;|&ensp;GPA: {props.gpa}</p>
            <p className='text-[#1d1d1d96] text-xs'>{props.description}</p>   
        </div>
    )
}

export default EducationComponent