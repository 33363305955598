import React from 'react'
import { EducationData } from './data';
import EducationComponent from './EducationComponent';

function Education() {
    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] max-w-[900px] px-6 md:px-20 py-10 md:py-10 shadow-xl'>
                <p className='text-2xl font-semibold mb-8'>Education</p>
                {EducationData.map((item) => (
                        <div key={item.id}>
                            <EducationComponent
                                id={item.id}
                                college={item.college}
                                major={item.major}
                                startyear={item.startyear}
                                endyear={item.endyear}
                                description={item.description}
                                color={item.color}
                                gpa={item.gpa}
                            />
                        </div>
                    ))}
            </div>
        </section>
    )
}

export default Education