import React from 'react'

function Banner() {
    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className=' w-full md:max-w-[900px] lg:w-[900px] mt-20 rounded-t-2xl shadow-xl'> {/* used to have bg-[#080808] */}
                <img src='/images/Astrobie.png' alt='Banner' className='object-cover w-full  rounded-t-2xl max-h-[200px] lg:max-h-[300px]'/> {/* used to have opacity-50 */}
            </div>
        </section>
    )
}

export default Banner