import React from 'react';
import { SwiperSlide } from 'swiper/react';

function StoriesCard(props) {
    return (
        <SwiperSlide className='mb-6 cursor-pointer'>
                <div className='bg-[#aeafadbd] w-[90px] h-[90px] rounded-full shadow-md flex items-center justify-center'>
                    <img className='w-20 h-20 border-[1px] border-[#fdfffc] rounded-full' src={props.url_story} alt={props.alt_story}/>
                </div>
        </SwiperSlide>
    )
}

export default StoriesCard