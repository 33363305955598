import React from 'react'

function Copyright() {
    return (
        <section className='bg-[#f3f4f5] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='pb-2 text-[#656565]'>
                <p className='text-xs'>Copyright &copy; 2024 Nova - Astrobie LLC. All Rights Reserved</p>
            </div>
        </section>
    )
}

export default Copyright