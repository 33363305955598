import React from 'react';
import AboutComponent from './AboutComponent';
import { Data } from './data';

function About() {
    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] max-w-[900px] px-6 md:px-20 py-10 md:py-10 shadow-xl'>
                <p className='text-2xl font-semibold mb-8'>About</p>
                {Data.map((item) => (
                    <div key={item.id}>
                        <AboutComponent
                            id={item.id}
                            about={item.about}
                        />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default About