import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import About from './components/About';
import Footer from './components/Footer';
import Banner from './components/Banner';
import Education from './components/Education';
import Head from './components/Head';
import Social from './components/Social';
import Links from './components/Links';
import Experience from './components/Experience';
import Stories from './components/Stories';
import Copyright from './components/Copyright';

import NewComponent from './components/NewComponent';

function App() {
  return (
    <BrowserRouter>
                    <Routes>
                        {/* Main /profile route will render NewComponent */}
        <Route path="/profile" element={<NewComponent />} />

{/* Static route for /profile/nameoftheuser */}
<Route path="/profile/user=1826d7j3uJohan" element={
  <>
    <Banner />
    <Head />
    <Stories />
    <Links />
    <About />
    <Social />
    <Education />
    <Experience />
    <Footer />
    <Copyright />
  </>
} />
                    </Routes>
            </BrowserRouter>
  );
}

export default App;
