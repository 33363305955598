import React from 'react';
import { Data } from './data';
import HeadComponent from './HeadComponent';

function Head() {

    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] w-full md:max-w-[900px] lg:w-[900px] px-6 md:px-20 py-10 md:py-10 shadow-xl'>
                    <div>
                        {Data.map((item) => (
                            <div key={item.id}>
                                <HeadComponent
                                    id={item.id}
                                    quote={item.quote}
                                    name={item.name}
                                    lastname={item.lastname}
                                    title={item.title}
                                    pronouns={item.pronouns}
                                    gender={item.gender}
                                    language={item.language}
                                    location={item.location}
                                    phone={item.phone}
                                    email={item.email}
                                    emailto={item.emailto}
                                    image={item.image}
                                    external_link_two={item.external_link_two}
                                    external_link_two_title={item.external_link_two_title}
                                />
                            </div>
                        ))}
                    </div>
            </div>
        </section>
    )
}

export default Head