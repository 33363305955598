import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SocialCard from './SocialCard';
import { SocialData } from './data';

// Import Swiper.js
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Social() {

    const [socialData, setSocialData] = useState([]);

    const fetchSocialData = async () => {
        try {
            const response = await axios.get('');
            setSocialData(response.data);
        } catch (error) {
            console.error('Error fetching social links:', error);
        }
    };

    useEffect(() => {
        fetchSocialData();
    }, []);

    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] w-full md:max-w-[900px] lg:w-[900px] py-10 md:py-10 shadow-xl'>
                <p className='text-2xl font-semibold mb-8 px-6 md:px-20'>Social</p>
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#1D65C1",
                        "--swiper-pagination-bullet-inactive-color": "#989997",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "8px",
                        "--swiper-pagination-bullet-horizontal-gap": "6px",
                    }}
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={false}
                    breakpoints={{
                        0: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        400: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                        640: {
                            slidesPerView: 1.2,
                            spaceBetween: 20,
                        },
                        735: {
                            slidesPerView: 1.2,
                            spaceBetween: 60,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        1124: {
                            slidesPerView: 8,
                            spaceBetween: 10,
                        }
                    }}
                    pagination={false}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper max-w-[1240px] w-full"
                >
                    
                    {SocialData.map((item) => (
                        <SwiperSlide className='pb-6 cursor-pointer'>
                            <SocialCard
                                key={item.id}
                                alt={item.alt}
                                link={item.link}
                                logo={item.logo}
                            />
                        </SwiperSlide>
                        
                    ))}
                </Swiper>
            </div>
        </section>
        
    )
}

export default Social