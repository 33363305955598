import React from 'react'
import { ExperienceData } from './data'
import ExperienceComponent from './ExperienceComponent'

function Experience() {
    return (
        <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
            <div className='bg-[#FDFFFC] max-w-[900px] lg:w-[900px] px-6 md:px-20 py-10 md:py-10 shadow-xl'>
                <p className='text-2xl font-semibold mb-8'>Experience</p>
                {ExperienceData.map((item) => (
                        <div key={item.id}>
                            <ExperienceComponent
                                id={item.id}
                                company_name={item.company_name}
                                title={item.title}
                                yearone={item.yearone}
                                yeartwo={item.yeartwo}
                                description={item.description}
                                color={item.color}
                            />
                        </div>
                    ))}
            </div>
        </section>
    )
}

export default Experience