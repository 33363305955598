import React from 'react'

function ExperienceComponent(props) {
  return (
    <div className={`border-l-[5px] ${props.color} px-3 my-2 py-2`}>
        <p className='text-base font-medium'><span>{props.company_name}&ensp;</span>
        <span className='font-light text-xs'>({props.yearone} - {props.yeartwo})</span></p>
        <div>
          <p className='text-sm font-light text-[#1d1d1d8e] mb-2'>{props.title}</p>
        </div>
        <p className='text-[#1d1d1d96] text-xs'>{props.description}</p>
    </div>
  )
}

export default ExperienceComponent